import logger from '@ethpass/logger';
import fetch, { RequestInfo, RequestInit } from 'node-fetch';

export default async function fetchJson<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const response = await fetch(input, init);

  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    const data = await response.json();
    if (process.env.NODE_ENV === 'development') {
      logger.info('## SWR FETCH', data);
    }
    return data;
  }

  throw new FetchError({
    message: response.statusText,
    status: response.status,
    // @ts-ignore
    response,
  });
}

export class FetchError extends Error {
  response: Response;
  data: {
    message: string;
  };
  status: number;
  constructor({
    message,
    response,
    data,
    status,
  }: {
    message: string;
    response: Response;
    status: number;
    data: {
      message: string;
    };
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.response = response;
    this.status = status;
    this.data = data ?? { message: message };
  }
}
