import React, { createContext, useContext, useState } from 'react';
type ProjectContent = {
  id?: string;
  name?: string;
  timezone?: string;
  keyCreated?: boolean;
};

const ProjectContext = createContext<{
  content: ProjectContent;
  setProject: (content: ProjectContent) => void;
}>({
  content: { name: '' },
  setProject: () => {},
});

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

const ProjectProvider = ({ children }) => {
  const [content, setContent] = useState<ProjectContent>({});

  const setProject = (content) => {
    setContent(content);
  };

  return (
    <ProjectContext.Provider value={{ setProject, content }}>{children}</ProjectContext.Provider>
  );
};

export { ProjectProvider };
