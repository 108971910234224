"use strict";
exports.__esModule = true;
exports.UniversalFieldAlignment = exports.ExpireAction = exports.TemplateImage = exports.BarcodeFormat = exports.PassLinkType = exports.GooglePassTypes = exports.ApplePassTypes = exports.Chains = exports.Platform = exports.Barcode = exports.Location = exports.Field = void 0;
var schemas_1 = require("passkit-generator/lib/schemas");
exports.Field = schemas_1.Field;
exports.Location = schemas_1.Location;
exports.Barcode = schemas_1.Barcode;
var Platform;
(function (Platform) {
    Platform["APPLE"] = "apple";
    Platform["GOOGLE"] = "google";
})(Platform = exports.Platform || (exports.Platform = {}));
var Chains;
(function (Chains) {
    Chains["EVM"] = "evm";
    Chains["FLOW"] = "flow";
    Chains["SOLANA"] = "solana";
})(Chains = exports.Chains || (exports.Chains = {}));
var ApplePassTypes;
(function (ApplePassTypes) {
    ApplePassTypes["GENERIC"] = "generic";
    ApplePassTypes["STORE_CARD"] = "storeCard";
    ApplePassTypes["EVENT_TICKET"] = "eventTicket";
    ApplePassTypes["COUPON"] = "coupon";
})(ApplePassTypes = exports.ApplePassTypes || (exports.ApplePassTypes = {}));
var GooglePassTypes;
(function (GooglePassTypes) {
    GooglePassTypes["OFFER"] = "offer";
    GooglePassTypes["LOYALTY"] = "loyalty";
    GooglePassTypes["GIFT_CARD"] = "giftcard";
    GooglePassTypes["EVENT_TICKET"] = "eventticket";
    GooglePassTypes["GENERIC"] = "generic";
})(GooglePassTypes = exports.GooglePassTypes || (exports.GooglePassTypes = {}));
var PassLinkType;
(function (PassLinkType) {
    PassLinkType["DISTRIBUTION"] = "distribution";
    PassLinkType["REDIRECT"] = "redirect";
})(PassLinkType = exports.PassLinkType || (exports.PassLinkType = {}));
var BarcodeFormat;
(function (BarcodeFormat) {
    BarcodeFormat["QR"] = "QR";
    BarcodeFormat["CODE_128"] = "CODE_128";
    BarcodeFormat["AZTEC"] = "AZTEC";
    BarcodeFormat["PDF417"] = "PDF417";
    BarcodeFormat["NONE"] = "NONE";
})(BarcodeFormat = exports.BarcodeFormat || (exports.BarcodeFormat = {}));
var TemplateImage;
(function (TemplateImage) {
    TemplateImage["APPLE_ICON"] = "apple:icon";
    TemplateImage["APPLE_LOGO"] = "apple:logo";
    TemplateImage["APPLE_STRIP"] = "apple:strip";
    TemplateImage["GOOGLE_LOGO"] = "google:logo";
    TemplateImage["GOOGLE_STRIP"] = "google:strip";
})(TemplateImage = exports.TemplateImage || (exports.TemplateImage = {}));
var ExpireAction;
(function (ExpireAction) {
    ExpireAction["SERVICE_FALLBACK"] = "SERVICE_FALLBACK";
    ExpireAction["TRANSFER_EVENT"] = "TRANSFER_EVENT";
    ExpireAction["GOOGLE_CALLBACK"] = "GOOGLE_CALLBACK";
    ExpireAction["DELEGATION_REVOKE"] = "DELEGATION_REVOKE";
    // Below not currently in use
    ExpireAction["USER_REVOKE"] = "USER_REVOKE";
    ExpireAction["PRESET_EXPIRATION"] = "PRESET_EXPIRATION";
    ExpireAction["APPLE_CALLBACK"] = "APPLE_CALLBACK";
})(ExpireAction = exports.ExpireAction || (exports.ExpireAction = {}));
var UniversalFieldAlignment;
(function (UniversalFieldAlignment) {
    UniversalFieldAlignment["LEFT"] = "left";
    UniversalFieldAlignment["MIDDLE"] = "middle";
    UniversalFieldAlignment["RIGHT"] = "right";
})(UniversalFieldAlignment = exports.UniversalFieldAlignment || (exports.UniversalFieldAlignment = {}));
