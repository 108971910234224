import { useEffect, useState } from 'react';
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/outline';
import { useDownloadModalContext } from '../../contexts/downloadModal';
import { Platform } from '@ethpass/types/pass';
import Image from 'next/image';
import addAppleWallet from '../../public/assets/apple-wallet-add.png';
import addGoogleWallet from '../../public/assets/google-wallet-add.png';
import QRCode from 'qrcode';
import Modal from './Modal';

export default function DownloadModal() {
  const { hideModal, open, content } = useDownloadModalContext();
  const [qrCode, setQRCode] = useState(null);
  const { distribution, platform } = content;

  useEffect(() => {
    if (!distribution) return;
    QRCode.toDataURL(distribution[platform].url, {}, function (err, url) {
      if (err) throw err;
      setQRCode(url);
    });
  }, [distribution]);

  return (
    <Modal isActive={open} onClose={hideModal}>
      <div className={`flex items-center mb-3 justify-start align-center`}>
        <button
          className="border-2 cursor-pointer items-center rounded-full flex-shrink-0 h-6 w-6 flex justify-center select-none border-gray-400 mr-2"
          onClick={hideModal}>
          <ArrowLeftIcon className="h-3 w-3 text-black" aria-hidden="true" />
        </button>
      </div>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5 flex flex-col justify-center align-center">
        <div className="mt-2 text-center">
          <p className="block text-sm font-medium text-gray-500 pointer-events-none">{`Scan QR code using your ${
            platform === Platform.GOOGLE ? 'Android' : 'Apple'
          } device`}</p>
          <div className="w-250 h-250 flex justify-center">
            <img src={qrCode} />
          </div>
          <p className="block text-sm font-medium text-gray-500 pointer-events-none mb-2">
            Or tap below to download directly on your mobile device.
          </p>
        </div>
        {platform && platform === Platform.APPLE ? (
          <a href={distribution[platform].url} download>
            <Image src={addAppleWallet} width={120} height={37} />
          </a>
        ) : (
          platform && (
            <a target="_blank" href={distribution[platform].url}>
              <Image src={addGoogleWallet} width={264} height={48} />
            </a>
          )
        )}
      </div>
    </Modal>
  );
}
