import React, { createContext, useContext, useState } from 'react';
import DownloadModal from '../ui/shared/DownloadModal';
import { Platform } from '@ethpass/types/pass';

const DownloadModalContext = createContext({
  open: false,
  content: { distribution: null, platform: null },
  showModal: ({ distribution, platform }) => {},
  hideModal: () => {},
});

export const useDownloadModalContext = () => {
  return useContext(DownloadModalContext);
};

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<{ distribution: any; platform: Platform } | null>({
    distribution: null,
    platform: null,
  });

  const showModal = ({ distribution, platform }) => {
    setContent({ distribution, platform });
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setTimeout(() => setContent({ distribution: null, platform: null }), 500);
  };

  return (
    <DownloadModalContext.Provider value={{ open, showModal, hideModal, content }}>
      {children}
      <DownloadModal />
    </DownloadModalContext.Provider>
  );
};

export { ModalProvider as DownloadModalProvider };
