import React, { createContext, useContext, useState } from 'react';
import ConfirmModal from '../ui/shared/ConfirmModal';

type ConfirmModalContent = {
  title?: string;
  message?: string;
  primaryAction?: {
    text: string;
    callback: () => void;
  };
  secondaryAction?: {
    text: string;
    callback: () => void;
  };
};

const ConfirmModalContext = createContext<{
  open: boolean;
  content: ConfirmModalContent;
  showModal: (content: ConfirmModalContent) => void;
  hideModal: () => void;
}>({
  open: false,
  content: { title: null, message: null, primaryAction: null, secondaryAction: null },
  showModal: () => {},
  hideModal: () => {},
});

export const useConfirmModalContext = () => {
  return useContext(ConfirmModalContext);
};

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ConfirmModalContent>({});

  const showModal = (content) => {
    setContent(content);
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setTimeout(() => setContent({}), 500);
  };

  return (
    <ConfirmModalContext.Provider value={{ open, showModal, hideModal, content }}>
      {children}
      <ConfirmModal />
    </ConfirmModalContext.Provider>
  );
};

export { ModalProvider as ConfirmModalProvider };
