"use strict";
exports.__esModule = true;
exports.FlowNetwork = exports.SolanaNetwork = exports.DelegationRegistry = exports.TokenInterface = exports.ChainId = exports.ChainName = void 0;
var ChainName;
(function (ChainName) {
    ChainName["MAINNET"] = "Ethereum";
    ChainName["SEPOLIA"] = "Sepolia";
    ChainName["MATIC"] = "Polygon";
    ChainName["AMOY"] = "Polygon Amoy";
    ChainName["OPTIMISM"] = "Optimism";
    ChainName["OPTIMISM_SEPOLIA"] = "Optimism Sepolia";
    ChainName["ARBITRUM"] = "Arbitrum One";
    ChainName["ARBITRUM_SEPOLIA"] = "Arbitrum Sepolia";
    ChainName["BINANCE_MAINNET"] = "Binance Smart Chain";
    ChainName["BINANCE_TESTNET"] = "Binance Smart Chain Testnet";
    ChainName["GNOSIS"] = "GNOSIS";
    ChainName["BASE"] = "Base";
    ChainName["BASE_SEPOLIA"] = "Base Sepolia Testnet";
    ChainName["HEDERA"] = "Hedera";
    ChainName["HEDERA_TESTNET"] = "Hedera Testnet";
})(ChainName = exports.ChainName || (exports.ChainName = {}));
var ChainId;
(function (ChainId) {
    ChainId[ChainId["MAINNET"] = 1] = "MAINNET";
    ChainId[ChainId["SEPOLIA"] = 11155111] = "SEPOLIA";
    ChainId[ChainId["MATIC"] = 137] = "MATIC";
    ChainId[ChainId["AMOY"] = 80002] = "AMOY";
    ChainId[ChainId["OPTIMISM"] = 10] = "OPTIMISM";
    ChainId[ChainId["OPTIMISM_SEPOLIA"] = 11155420] = "OPTIMISM_SEPOLIA";
    ChainId[ChainId["ARBITRUM"] = 42161] = "ARBITRUM";
    ChainId[ChainId["ARBITRUM_SEPOLIA"] = 421614] = "ARBITRUM_SEPOLIA";
    ChainId[ChainId["BINANCE_MAINNET"] = 56] = "BINANCE_MAINNET";
    ChainId[ChainId["BINANCE_TESTNET"] = 97] = "BINANCE_TESTNET";
    ChainId[ChainId["GNOSIS"] = 100] = "GNOSIS";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
    ChainId[ChainId["BASE_SEPOLIA"] = 84532] = "BASE_SEPOLIA";
    ChainId[ChainId["HEDERA"] = 295] = "HEDERA";
    ChainId[ChainId["HEDERA_TESTNET"] = 296] = "HEDERA_TESTNET";
    ChainId[ChainId["HARDHAT"] = 31337] = "HARDHAT";
})(ChainId = exports.ChainId || (exports.ChainId = {}));
var TokenInterface;
(function (TokenInterface) {
    TokenInterface["ERC721"] = "0x80ac58cd";
    TokenInterface["ERC721Enumerable"] = "0x780e9d63";
    TokenInterface["ERC721TokenReceiver"] = "0x150b7a02";
    TokenInterface["ERC721Metadata"] = "0x5b5e139f";
    TokenInterface["ERC1155"] = "0xd9b67a26";
    TokenInterface["ERC1155TokenReceiver"] = "0x4e2312e0";
    TokenInterface["ERC1155Metadata"] = "0x0e89341c";
})(TokenInterface = exports.TokenInterface || (exports.TokenInterface = {}));
var DelegationRegistry;
(function (DelegationRegistry) {
    DelegationRegistry["DELEGATE_CASH"] = "delegate.cash";
    DelegationRegistry["WARM_XYZ"] = "warm.xyz";
})(DelegationRegistry = exports.DelegationRegistry || (exports.DelegationRegistry = {}));
var SolanaNetwork;
(function (SolanaNetwork) {
    SolanaNetwork["MAINNET"] = "mainnet";
    SolanaNetwork["TESTNET"] = "testnet";
    SolanaNetwork["DEVNET"] = "devnet";
})(SolanaNetwork = exports.SolanaNetwork || (exports.SolanaNetwork = {}));
var FlowNetwork;
(function (FlowNetwork) {
    FlowNetwork["MAINNET"] = "mainnet";
    FlowNetwork["TESTNET"] = "testnet";
})(FlowNetwork = exports.FlowNetwork || (exports.FlowNetwork = {}));
