import { ConfirmModalProvider } from '../contexts/confirmModal';
import { DownloadModalProvider } from '../contexts/downloadModal';
import { SessionProvider } from 'next-auth/react';
import { SWRConfig } from 'swr';
import { Toaster } from 'react-hot-toast';
import { AppProps } from 'next/app';
import fetchJson from '../lib/fetcher';
import Head from 'next/head';
import logger from '@ethpass/logger';
import Script from 'next/script';
import smoothscroll from 'smoothscroll-polyfill';
import '../ui/styles/globals.css';
import dynamic from 'next/dynamic';
import { ProjectProvider } from '../contexts/project';
import { mainnet, polygon } from '@wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

//@ts-ignore
const {} = dynamic(import('tw-elements'), { ssr: false });
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { URLS } from '@ethpass/core/provider';
import { ConnectKitProvider, getDefaultClient } from 'connectkit';
import { Session } from 'next-auth';

// @NOTE Temporary fix for type errors from deprecated code for SIWE
declare module 'next-auth' {
  interface Session {
    address: string;
    expires: any;
  }
}

// kick off the polyfill!
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const defaultChains = [mainnet, polygon];
const { chains, provider } = configureChains(defaultChains, [
  jsonRpcProvider({
    rpc: (chain) => {
      if (!URLS[chain.id]) return null;
      return { http: URLS[chain.id] };
    },
  }),
]);

const client = createClient(
  getDefaultClient({
    appName: 'ethpass',
    chains,
    provider,
    autoConnect: false,
  })
);
export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>
        <Head>
          <Script src="https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js" />
          <Script
            async
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC8DVp9cg0NTNEzWZeQl8vajeus3Cb4xqM&libraries=places"
          />

          <script async src="https://www.googletagmanager.com/gtag/js?id=G-YJ7W8FE846"></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-YJ7W8FE846');
          `,
            }}
          />
          {process.env.NODE_ENV === 'production' && (
            <script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js.hs-scripts.com/22597840.js"
            />
          )}

          <title>ethpass | Verify NFT ownership using Apple and Google Wallet</title>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />

          <link rel="manifest" href="/site.webmanifest" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <meta
            name="description"
            content="Create a new channel for engaging with your collectors by connecting their NFTs to passes for tickets, loyalty
            cards, or coupons - no app required."
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <SWRConfig
          value={{
            fetcher: fetchJson,
            onError: (err) => {
              if (process.env.NODE_ENV === 'development') {
                logger.error('## SWR ERROR', err);
              }
            },
          }}>
          <SessionProvider session={session} refetchInterval={0}>
            <DownloadModalProvider>
              <ConfirmModalProvider>
                <ProjectProvider>
                  {/* @ts-ignore */}
                  <Component {...pageProps} />
                </ProjectProvider>
              </ConfirmModalProvider>
            </DownloadModalProvider>
          </SessionProvider>
        </SWRConfig>
        <Toaster />
      </ConnectKitProvider>
    </WagmiConfig>
  );
}
